import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import { Button } from "@mui/material"
import { initLogger, useSessionContext } from "../utils"
import { Cards, DisclaimerCard } from "../components"
import Layout from "../layouts/Layout"
import "../styles/style.css"

export default ({ data }) => {
  const { setSession, setStartTime, setTgmSession } = useSessionContext()

  const nodes = data.data.edges
  const configData = {}
  data.config.edges.forEach((item) => {
    configData[item.node.data.Name] = item.node.data.config
  })

  const { survey_name } = configData

  useEffect(() => {
    initLogger(survey_name).then((res) => {
      setSession(res.session)
      setStartTime(res.localTime)
    })
  }, [survey_name, setSession, setStartTime])

  useEffect(() => {
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    const value = params.get("sessionID")
    if (value !== undefined) {
      setTgmSession(value)
    }
  }, [setTgmSession])

  return (
    <Layout footer={configData.footer}>
      <div className="header">
        <h1>{configData.title}</h1>
        <h2>{configData.tagline}</h2>
      </div>
      <DisclaimerCard instructions={configData.instructions} />
      <Cards nodes={nodes} people={data.people.edges} />
      <Button variant="contained" onClick={() => navigate("/quiz")}>
        {configData.click_to_complete}
      </Button>
    </Layout>
  )
}

export const query = graphql`
  query {
    data: allAirtable(filter: { table: { eq: "Claims" } }) {
      edges {
        node {
          data {
            name
            slug
            claim
            correctText
            readMore
            image {
              id
              filename
              url
            }
            fontColor
          }
        }
      }
    }
    config: allAirtable(filter: { table: { eq: "config" } }) {
      edges {
        node {
          data {
            Name
            config
          }
        }
      }
    }
    people: allAirtable(filter: { table: { eq: "people" } }) {
      edges {
        node {
          data {
            Name
            key
            Picture {
              url
            }
          }
        }
      }
    }
  }
`
